@import '../../styles/breakpoints';

.container {
  width: 100%;
  max-width: var(--main-section-width);
  margin-left: auto;
  margin-right: auto;

  @include device-large {
    max-width: var(--container-width);
  }

  &.branded {
    background-color: var(--color-neutral-lighter);
    box-sizing: content-box;

    @include device-large {
      padding-left: var(--size-spacing-08);
      padding-right: var(--size-spacing-08);
    }
  }

  // Temporary override for carepack typography until they are standardised and implemented in carepack
  :global(.cp-heading-small) {
    font-size: var(--font-size-04);
    line-height: var(--font-size-06);
    font-weight: 500;
  }
}

@include device-large {
  .grid {
    display: grid;
    grid-template-columns: minmax(var(--main-section-width), 1fr) minmax(0, 1fr);
    column-gap: var(--size-spacing-07);
    row-gap: 0;
  }
}

.main {
  .totalsWrapper {
    padding: 20px 0;
    border: none;

    @include device-large {
      display: none;
    }
  }
}

.paddedContainer {
  padding: 0 20px;

  @include device-large {
    padding: 0;
  }
}

.cover {
  background: var(--color-white);
  position: relative;
}

.pageOwner {
  position: relative;

  &.noAvatar {
    padding-top: var(--size-spacing-05);
  }
}

.totalsWrapper {
  background: var(--color-white);
  padding: var(--size-spacing-06);
  max-height: 508px;
  flex-direction: column;
  border: 1px solid var(--color-neutral-600);
  border-radius: 4px;
}

.callsToAction {
  display: flex;
  justify-content: center;

  @include device-small {
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    background-color: unset;
    border-top: none;
  }

  @include device-large {
    min-width: initial;
    width: 100%;
    align-items: center;
  }
}

.pageOwnerAvatar {
  z-index: 1;
  position: absolute;
  left: var(--size-spacing-04);
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.features {
  background-color: var(--color-white);

  &:has(:global(#page-features-container *)) {
    border-top: 1px solid var(--color-neutral-600);
  }

  &:not(:has(:global(#page-features-container *))) {
    display: none;
  }

  @include device-large {
    display: grid;
    background-color: transparent;
  }
}

.bottomWrapper {
  background: var(--color-white);
  border-top: 1px solid var(--color-neutral-600);
}

.bottom {
  padding: var(--size-spacing-05) var(--size-spacing-05) var(--size-spacing-05) 0;

  @include device-large {
    padding: var(--size-spacing-05)
      calc((100% - (var(--container-width) - (var(--outside-gutters) * 2))) / 2);
  }
}

.section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.topBorder {
  border-top: 1px solid var(--color-neutral-600);
}

.bottomBorder {
  border-bottom: 1px solid var(--color-neutral-600);
}

.noBorderBottom {
  border-bottom: none;
}

.hideSection {
  display: none;
}
