@import '../../styles/breakpoints';

.owner {
  text-align: center;
  @include device-large {
    max-width: 60%;
  }
}

.bottomFooter {
  border-top: 1px solid var(--color-neutral-600);
  padding-left: var(--size-spacing-05);
  padding-right: var(--size-spacing-05);
  padding-top: var(--size-spacing-05);
  text-align: center;
  @include device-large {
      max-width: 90%;
  }
}

.textBody {
  color: var(--color-text-light);
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:hover {
    color: var(--color-text-dark);
  }
}
