.container {
  border-bottom: 1px solid var(--color-neutral-600);
}

.pageOwnerActions {
  padding: var(--size-spacing-03) var(--size-spacing-04);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview {
  position: sticky;
  top: 0;
  z-index: 2;
}

.tab {
  border-radius: 4px;
  display: inline-block;
  font-size: var(--font-size-01);
  font-weight: var(--font-weight-medium);
  height: var(--size-spacing-05);
  padding: var(--size-spacing-01) var(--size-spacing-02);
  text-transform: uppercase;
  background-color: var(--color-blue-300);
  color: var(--color-blue-base);
}