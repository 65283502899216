@import '../../styles/breakpoints';

.header {
  display: flex;
  justify-content: space-between;
}

.section {
  border-top: 1px solid var(--color-neutral-600);
}
