@import '../../styles/breakpoints';

.alert {
  border-top: 1px solid var(--color-red-400);
  border-bottom: 1px solid var(--color-red-400);
  background-color: var(--color-red-300);
  display: inline-block;
  width: 100%;
  padding-top: var(--size-spacing-04);
  padding-bottom: var(--size-spacing-04);
}

.container {
  width: 100%;
  max-width: var(--brand-container-width, var(--container-width));
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.errorIcon {
  width: 36px;
  height: 36px;
  fill: var(--color-red-400);
  margin: 0 var(--size-spacing-03);
}

.body {
  flex-grow: 1;
  font-size: var(--font-size-03);
}

.closeButton {
  margin: 0 var(--size-spacing-03);
  background: none;
  border: none;
  padding: 0;
  line-height: 0;
  cursor: pointer;
}

.closeIcon {
  fill: var(--color-grey-300);
}

.closeIcon:hover {
  fill: var(--color-grey-400);
}

