.page {
  background-color: var(--brand-color-background, var(--color-neutral-lighter));
  background-image: var(--brand-background-image);
  background-attachment: fixed;
  background-size: cover;

  @media print {
   display: none !important;
  }
}
