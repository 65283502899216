@import '../../styles/breakpoints';

.modal {
  max-height: calc(100vh - 2 * var(--modal-padding));
  width: 90vw;
  text-align: left;

  @include device-small {
    width: 80vw;
  }

  @include device-medium {
    width: 65vw;
  }

  @include device-large {
    width: 50vw;
  }
}

.section {
  max-height: calc(((100vh - 292px) - (var(--size-spacing-04) * 2)));
  overflow-y: auto;

  @include device-small {
    max-height: calc(((100vh - 292px) - (var(--size-spacing-05) * 2)));
  }
}

.groupMemberList {
  padding-right: var(--size-spacing-04);

  @include device-small {
    padding-right: var(--size-spacing-05);
  }

  &.withBorder {
    border-bottom: 1px solid var(--color-neutral-base);
  }
}

.modalBody {
  padding: var(--size-spacing-05);

  @include device-small {
    padding: var(--size-spacing-07) var(--size-spacing-07)
      var(--size-spacing-06) var(--size-spacing-07);
  }
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: var(--size-spacing-05);
  box-shadow: 1px 0 5px 0px var(--color-text-lighter);
}

.loadMoreButton {
  display: flex;
  align-items: center;
  margin: var(--size-spacing-05) 0 var(--size-spacing-02) 0;
  border: none;
  background-color: transparent;
}
