.icon {
  display: contents;

  svg {
    fill: var(--color-grey-400);
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
}
