@import '../../styles/breakpoints';


.donationSummaryList {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include device-medium {
    grid-template-columns: repeat(4, 1fr);
  }
}
