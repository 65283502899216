.container {
  text-align: center;
  margin-bottom: var(--size-spacing-06);
  margin-left: auto;
  margin-right: auto;
}

.icon {
  vertical-align: top;
  width: 48px;
  height: 48px;
  display: inline-block;
}

.quote {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-top: var(--size-spacing-04);
  margin-bottom: var(--size-spacing-03);
  text-align: center;
}

.text {
  margin: 0;
  padding: 0;
}
