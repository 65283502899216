.info {
  padding-bottom: var(--size-spacing-02);
}

.info > * {
  margin: var(--size-spacing-01);
}

.header {
  margin: 0;
}
