.cpBtnTertiary {
  color: var(--color-neutral-lighter);
  background: #ff6200;
}

.cpBtnTertiary:hover {
  background: #cc4e00;
}
.cpBtnTertiary:focus {
  box-shadow: inset 0 0 0 3px #993b00,0 0 2px 2px rgba(0,0,0,.1);
}
.cpBtnTertiary:active {
  background: #993b00;
}