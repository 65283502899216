.mediaWrapper {
  text-align: center;
  margin-bottom: var(--size-spacing-06);
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
}

.video {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
}

.container {
  display: flex;
  flex-direction: column;
}
